<template>
    <comp-form title="标签" ref="comp_form" submit-api="/gateway/api/sycompany/pc/label/createLabel" edit-api="/gateway/api/sycompany/pc/label/updateLabel" detail-api="/gateway/api/sycompany/pc/label/getLabelById" id-key="labelById" :submit-before="onSubmitBefore" @on-submit="onReset">
        <template v-slot:form-box="data">
            <elem-form-item title="标签名称" name="labelName">
                <template v-slot:default="item">
                    <Input style="width:300px" v-model.trim="data.form.labelName" :placeholder="item.cfg.ph"></Input>
                </template>
            </elem-form-item>
            <elem-form-item title="排序" name="sort">
                <template v-slot:default="item">
                    <Input style="width:300px" type="number" v-model.trim="data.form.sort" :placeholder="item.cfg.ph"></Input>
                </template>
            </elem-form-item>
            <elem-form-item title="标签图标" name="icon">
                <elem-upload :value="data.form.icon" @on-change="onChangeIcon"></elem-upload>
            </elem-form-item>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../components/CompForm.vue"
import ElemUpload from "../components/elem-upload.vue"
import ElemFormItem from "../components/elem-form-item.vue"

export default {
    components: { CompForm, ElemUpload, ElemFormItem },

    methods: {
        /**
         * 打开表单窗口
         */
        open(id) {
            this.$refs.comp_form.open(id)
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(data) {
            data.oemCode = parent.vue.oemInfo.oemCode
            data.labelType = this.$route.query.labelType
            data.parentId = this.$route.query.parentId
            return data
        },

        onChangeIcon(e) {
            this.$refs.comp_form.setValue("icon", e.value.url)
        },
    },
}
</script>
